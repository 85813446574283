import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/admin/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenu from "@/view/layout/admin/Menu.vue";
import { NOTI_ADMIN } from "@/core/services/store/profile.module.js";
import HeaderNoti from "@/view/layout/header/HeaderNoti.vue";
import { ACCOUNT } from "@/core/services/store/auth.module";
export default {
  name: "KTHeader",
  data: function data() {
    return {
      tabIndex: 0,
      permission: {},
      searchItem: "",
      polling: null
    };
  },
  components: {
    KTTopbar: KTTopbar,
    KTMenu: KTMenu,
    HeaderNoti: HeaderNoti
  },
  mounted: function mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init(this.$refs["kt_header"], this.$refs["kt_header_mobile"]); // Init Header Menu

    KTLayoutHeaderMenu.init(this.$refs["kt_header_menu"], this.$refs["kt_header_navs"]); // setting permission

    var permission = {};
    var settingPermission = this.checkPermission("admin_role", 1) || this.checkPermission("admin_bank", 1) || this.checkPermission("admin_china_thai", 1) || this.checkPermission("admin_exchange_rate", 1) || this.checkPermission("admin_delivery", 1);
    permission["admin_user"] = this.checkPermission("admin_user", 0);
    permission["admin_order"] = this.checkPermission("admin_order", 0);
    permission["admin_topup"] = this.checkPermission("admin_topup", 0);
    permission["admin_balance"] = this.checkPermission("admin_balance", 0);
    permission["admin_payshipping"] = this.checkPermission("admin_payshipping", 0);
    permission["admin_chat"] = this.checkPermission("admin_chat", 0);
    permission["admin_payment"] = this.checkPermission("admin_payment", 0);
    permission["admin_lot"] = this.checkPermission("admin_lot", 0);
    permission["admin_seller"] = this.checkPermission("admin_seller", 0);
    permission["setting"] = settingPermission;
    this.permission = permission;
    var headerRef = this.$refs["kt_header_navs"];
    setTimeout(function () {
      headerRef.querySelectorAll("a.menu-link").forEach(function (item) {
        item.addEventListener("click", function () {
          KTLayoutHeaderMenu.getOffcanvas().hide();
        });
      });
    }, 400);
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["layoutConfig", "getClasses", "currentPermission", "getCurrentNotiAdmin"])), {}, {
    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled: function headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses: function headerClasses() {
      var classes = this.getClasses("header");

      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }

      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses: function headerMenuClasses() {
      var classes = this.getClasses("header_menu");

      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }

      return null;
    },

    /**
     * Check if header container is fluid
     */
    widthFluid: function widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    }
  }),
  methods: {
    hasActiveChildren: function hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    loadData: function loadData() {
      this.$store.dispatch(NOTI_ADMIN);
    },
    loadAccount: function loadAccount() {
      this.$store.dispatch(ACCOUNT);
    },
    poll: function poll() {
      var _this = this;

      this.polling = setInterval(function () {
        _this.loadData();
      }, 9000);
    },

    /**
     * Set current active on click
     * @param event
     */
    setActiveTab: function setActiveTab(event) {
      var target = event.target;

      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      var tab = target.closest('[role="tablist"]');
      var links = tab.querySelectorAll(".nav-link"); // remove active tab links

      for (var i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      } // set clicked tab index to bootstrap tab


      this.tabIndex = parseInt(target.getAttribute("data-tab")); // set current active tab

      target.classList.add("active");
    },
    enterSearch: function enterSearch() {
      var url = this.searchItem;
      this.$router.push("/shop/search/" + encodeURIComponent(url.replace(".html", "")));
    },
    checkPermission: function checkPermission(name, type) {
      if (!this.currentPermission) {
        return false;
      }

      var permissionRole = this.currentPermission.permissionRole;
      var find = permissionRole.find(function (x) {
        return x.permissionInfo && x.permissionInfo.permissionName === name;
      });

      if (!find) {
        return false;
      }

      if (type === 0 && find.readableRole) {
        return true;
      }

      if (type === 1 && find.writeableRole) {
        return true;
      }

      return false;
    }
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.polling);
  },
  created: function created() {
    this.loadData();
    this.poll();
    this.loadAccount();
  }
};