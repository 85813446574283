import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
import { LOGOUT } from "@/core/services/store/auth.module";
export default {
  name: "KTHeaderMobile",
  components: {},
  mounted: function mounted() {
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["layoutConfig", "getClasses", "currentUser", "getCurrentNotiAdmin"])), {}, {
    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo: function headerLogo() {
      return this.layoutConfig("self.logo.sticky");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses: function headerClasses() {
      var classes = this.getClasses("header_mobile");

      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }

      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled: function asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    }
  }),
  methods: {
    totalNoti: function totalNoti() {
      var total = 0;

      for (var key in this.getCurrentNotiAdmin) {
        if (key !== "cart") {
          total += this.getCurrentNotiAdmin[key] || 0;
        }
      }

      return total;
    },
    onLogout: function onLogout() {
      var _this = this;

      this.$store.dispatch(LOGOUT).then(function () {
        return _this.$router.push({
          name: "login"
        });
      });
    },
    onOver: function onOver() {
      this.$refs.dropdown.visible = true;
    },
    onLeave: function onLeave() {
      this.$refs.dropdown.visible = false;
    }
  }
};