import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
export default {
  name: "KTMenu",
  data: function data() {
    return {
      permission: {}
    };
  },
  computed: _objectSpread({}, mapGetters(["getIsAdmin", "getCurrentNotiAdmin", "currentPermission"])),
  mounted: function mounted() {
    // setting permission
    var permission = {};
    var settingPermission = this.checkPermission("admin_role", 1) || this.checkPermission("admin_bank", 1) || this.checkPermission("admin_china_thai", 1) || this.checkPermission("admin_exchange_rate", 1) || this.checkPermission("admin_delivery", 1);
    permission["admin_user"] = this.checkPermission("admin_user", 0);
    permission["admin_order"] = this.checkPermission("admin_order", 0);
    permission["admin_topup"] = this.checkPermission("admin_topup", 0);
    permission["admin_balance"] = this.checkPermission("admin_balance", 0);
    permission["admin_payshipping"] = this.checkPermission("admin_payshipping", 0);
    permission["admin_chat"] = this.checkPermission("admin_chat", 0);
    permission["admin_payment"] = this.checkPermission("admin_payment", 0);
    permission["admin_lot"] = this.checkPermission("admin_lot", 0);
    permission["admin_lot"] = this.checkPermission("admin_lot", 0);
    permission["setting"] = settingPermission;
    this.permission = permission;
  },
  methods: {
    hasActiveChildren: function hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    checkPermission: function checkPermission(name, type) {
      if (!this.currentPermission) {
        return false;
      }

      var permissionRole = this.currentPermission.permissionRole;
      var find = permissionRole.find(function (x) {
        return x.permissionInfo && x.permissionInfo.permissionName === name;
      });

      if (!find) {
        return false;
      }

      if (type === 0 && find.readableRole) {
        return true;
      }

      if (type === 1 && find.writeableRole) {
        return true;
      }

      return false;
    }
  }
};