var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"kt_header",staticClass:"header flex-column header-fixed",attrs:{"id":"kt_header"}},[_c('HeaderNoti'),_c('div',{staticClass:"header-top admin header-menu"},[_c('div',{staticClass:"container p-0"},[_c('div',{ref:"kt_header_menu",staticClass:"d-none d-lg-flex align-items-center mr-3",attrs:{"id":"kt_header_menu"}},[_c('div',{staticClass:"mr-14"},[_c('router-link',{attrs:{"to":"/admin"}},[_c('img',{staticClass:"max-w-50px",attrs:{"alt":"Logo","src":_vm.layoutConfig('self.logo.default')}})])],1),_c('ul',{staticClass:"header-tabs nav menu-nav align-self-end font-size-lg",attrs:{"role":"tablist"}},[(_vm.permission['admin_user'])?_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":"/admin/users"},on:{"click":_vm.setActiveTab},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"nav-link py-4 px-5",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_vm._v(" ผู้ใช้งาน ")])]}}],null,false,1201369753)})],1):_vm._e(),(_vm.permission['admin_balance'] || _vm.permission['admin_topup'])?_c('li',{staticClass:"nav-item noti"},[_c('router-link',{attrs:{"to":"/admin/wallet"},on:{"click":_vm.setActiveTab},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"nav-link py-4 px-5",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_vm._v(" กระเป๋าเงิน "),(
                    _vm.getCurrentNotiAdmin &&
                      (_vm.getCurrentNotiAdmin.withdraw ||
                        _vm.getCurrentNotiAdmin.topup)
                  )?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNotiAdmin.withdraw + _vm.getCurrentNotiAdmin.topup)+" ")]):_vm._e()])]}}],null,false,2151221993)})],1):_vm._e(),(_vm.permission['admin_order'])?_c('li',{staticClass:"nav-item noti"},[_c('router-link',{attrs:{"to":"/admin/shop/order"},on:{"click":_vm.setActiveTab},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"nav-link py-4 px-5",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_vm._v(" ใบสั่งซื้อ "),(_vm.getCurrentNotiAdmin && _vm.getCurrentNotiAdmin.trade)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNotiAdmin.trade)+" ")]):_vm._e()])]}}],null,false,1837502348)})],1):_vm._e(),(_vm.permission['admin_lot'] || _vm.permission['admin_payshipping'])?_c('li',{staticClass:"nav-item noti menu-item menu-item-submenu menu-item-open-dropdown mr-0",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"nav-link menu-link menu-toggle py-4 px-5 radius-bottom-0 align-items-center",class:{
                active:
                  _vm.hasActiveChildren('/admin/forwarder') ||
                  _vm.hasActiveChildren('/admin/shippay/bill') ||
                  _vm.hasActiveChildren('/admin/lot')
              }},[_vm._v(" ฝากส่งพัสดุ "),(_vm.getCurrentNotiAdmin && _vm.getCurrentNotiAdmin.bill)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNotiAdmin.bill)+" ")]):_vm._e(),_c('span',{staticClass:"svg-icon svg-icon-md"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Navigation/Angle-down.svg"}})],1),(_vm.getCurrentNotiAdmin && _vm.getCurrentNotiAdmin.track)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNotiAdmin.track)+" ")]):_vm._e()]),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.permission['admin_payshipping'])?_c('router-link',{attrs:{"to":"/admin/forwarder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link noti",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-list-2"}),_c('span',{staticClass:"font-size-lg position-relative pr-4",class:{ 'text-dark': !isActive }},[_vm._v(" รหัสพัสดุ "),(
                            _vm.getCurrentNotiAdmin && _vm.getCurrentNotiAdmin.track
                          )?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNotiAdmin.track)+" ")]):_vm._e()])])])]}}],null,false,1728816395)}):_vm._e(),(_vm.permission['admin_payshipping'])?_c('router-link',{attrs:{"to":"/admin/shippay/bill"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var href = ref.href;
                          var navigate = ref.navigate;
                          var isActive = ref.isActive;
                          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-mail-1"}),_c('span',{staticClass:"font-size-lg position-relative pr-4",class:{ 'text-dark': !isActive }},[_vm._v(" ใบส่งพัสดุ "),(
                            _vm.getCurrentNotiAdmin && _vm.getCurrentNotiAdmin.bill
                          )?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNotiAdmin.bill)+" ")]):_vm._e()])])])]}}],null,false,2306193904)}):_vm._e(),(_vm.permission['admin_lot'])?_c('router-link',{attrs:{"to":"/admin/lot"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var href = ref.href;
                          var navigate = ref.navigate;
                          var isActive = ref.isActive;
                          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers-1"}),_c('span',{staticClass:"font-size-lg",class:{ 'text-dark': !isActive }},[_vm._v(" ล็อต ")])])])]}}],null,false,2375967357)}):_vm._e()],1)])]):_vm._e(),(_vm.permission['admin_payment'])?_c('li',{staticClass:"nav-item noti"},[_c('router-link',{attrs:{"to":"/admin/payments"},on:{"click":_vm.setActiveTab},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"nav-link py-4 px-5",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_vm._v(" โอนเงินไปจีน "),(_vm.getCurrentNotiAdmin && _vm.getCurrentNotiAdmin.payment)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNotiAdmin.payment)+" ")]):_vm._e()])]}}],null,false,2880170494)})],1):_vm._e(),(_vm.permission['admin_chat'])?_c('li',{staticClass:"nav-item noti"},[_c('router-link',{attrs:{"to":"/admin/chat"},on:{"click":_vm.setActiveTab},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"nav-link py-4 px-5",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_vm._v(" แชท "),(_vm.getCurrentNotiAdmin && _vm.getCurrentNotiAdmin.msg)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNotiAdmin.msg)+" ")]):_vm._e()])]}}],null,false,693751941)})],1):_vm._e(),(_vm.permission['setting'])?_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":"/admin/setting"},on:{"click":_vm.setActiveTab},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"nav-link py-4 px-5",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_vm._v(" ตั้งค่า ")])]}}],null,false,4039335341)})],1):_vm._e(),(_vm.permission['admin_seller'])?_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":"/admin/seller"},on:{"click":_vm.setActiveTab},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"nav-link py-4 px-5",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_vm._v(" ขายสินค้า ")])]}}],null,false,2105043027)})],1):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":"/dashboard"},on:{"click":_vm.setActiveTab},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"nav-link py-4 px-5",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_vm._v(" หน้าผู้ใช้ ")])]}}])})],1)])]),_c('KTTopbar')],1)]),_c('div',{staticClass:"header-bottom subheader py-4 subheader-solid"},[_c('div',{staticClass:"container d-flex align-items-center justify-content-end flex-wrap flex-sm-nowrap"},[_c('div',{staticClass:"ml-5",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"input-group input-group-solid input-group-lg",staticStyle:{"width":"100%"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchItem),expression:"searchItem"}],staticClass:"form-control pr-2",attrs:{"type":"text","placeholder":"วางลิงก์สินค้าจากเว็บไซต์จีน taobao/1688/alibaba/shopee"},domProps:{"value":(_vm.searchItem)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.enterSearch.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.searchItem=$event.target.value}}}),(_vm.searchItem)?_c('span',{staticClass:"icon-xl la la-times-circle opacity-40 pointer",on:{"click":function($event){_vm.searchItem = ''}}}):_vm._e(),_c('div',{staticClass:"input-group-append pointer",on:{"click":_vm.enterSearch}},[_c('span',{staticClass:"input-group-text"},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/General/Search.svg"}})],1)])])])])])]),_c('div',{staticClass:"d-lg-none header-bottom"},[_c('div',{staticClass:"container"},[_c('div',{ref:"kt_header_navs",staticClass:"header-navs header-navs-right",attrs:{"id":"kt_header_navs"}},[_c('div',{staticClass:"tab-pane py-5 show active"},[_c('div',{staticClass:"header-menu header-menu-mobile header-menu-layout-default"},[_c('KTMenu')],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }