var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[(_vm.permission['admin_user'])?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/admin/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2"},[_vm._v(" ผู้ใช้งาน ")])])]}}],null,false,3250482407)})],1):_vm._e(),(_vm.permission['admin_balance'] || _vm.permission['admin_topup'])?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/admin/wallet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2 noti"},[_c('span',{staticClass:"menu-m"},[_vm._v(" กระเป๋าเงิน "),(
                _vm.getCurrentNotiAdmin &&
                  (_vm.getCurrentNotiAdmin.withdraw || _vm.getCurrentNotiAdmin.topup)
              )?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNotiAdmin.withdraw + _vm.getCurrentNotiAdmin.topup)+" ")]):_vm._e()])])])]}}],null,false,1889509519)})],1):_vm._e(),(_vm.permission['admin_order'])?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/admin/shop/order"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2 noti"},[_c('span',{staticClass:"menu-m"},[_vm._v(" ใบสั่งซื้อ "),(_vm.getCurrentNotiAdmin && _vm.getCurrentNotiAdmin.trade)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNotiAdmin.trade)+" ")]):_vm._e()])])])]}}],null,false,4183669504)})],1):_vm._e(),(_vm.permission['admin_payshipping'])?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/admin/forwarder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2"},[_vm._v(" รหัสพัสดุ ")])])]}}],null,false,3095562301)})],1):_vm._e(),(_vm.permission['admin_payshipping'])?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/admin/shippay/bill"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2 noti"},[_c('span',{staticClass:"menu-m"},[_vm._v(" ใบส่งพัสดุ "),(_vm.getCurrentNotiAdmin && _vm.getCurrentNotiAdmin.bill)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNotiAdmin.bill)+" ")]):_vm._e()])])])]}}],null,false,1943893728)})],1):_vm._e(),(_vm.permission['admin_lot'])?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/admin/lot"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2 noti"},[_c('span',{staticClass:"menu-m"},[_vm._v(" ล็อต ")])])])]}}],null,false,1889672783)})],1):_vm._e(),(_vm.permission['admin_payment'])?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/admin/payments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2 noti"},[_c('span',{staticClass:"menu-m"},[_vm._v(" โอนเงินไปจีน "),(_vm.getCurrentNotiAdmin && _vm.getCurrentNotiAdmin.payment)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNotiAdmin.payment)+" ")]):_vm._e()])])])]}}],null,false,1171506930)})],1):_vm._e(),(_vm.permission['admin_chat'])?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/admin/chat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2 noti"},[_c('span',{staticClass:"menu-m"},[_vm._v(" แชท "),(_vm.getCurrentNotiAdmin && _vm.getCurrentNotiAdmin.msg)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNotiAdmin.msg)+" ")]):_vm._e()])])])]}}],null,false,3945746569)})],1):_vm._e(),(_vm.permission['setting'])?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/admin/setting"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2"},[_vm._v(" ตั้งค่า ")])])]}}],null,false,3453881875)})],1):_vm._e(),_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2"},[_vm._v(" หน้าผู้ใช้ ")])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }