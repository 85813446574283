import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import i18nService from "@/core/services/i18n.service.js";
export default {
  name: "KTTopbar",
  data: function data() {
    return {
      languageFlag: "",
      languages: i18nService.languages
    };
  },
  methods: {
    onLanguageChanged: function onLanguageChanged() {
      this.languageFlag = this.languages.find(function (val) {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    onLogout: function onLogout() {
      var _this = this;

      this.$store.dispatch(LOGOUT).then(function () {
        return _this.$router.push({
          name: "login"
        });
      });
    },
    onOver: function onOver() {
      this.$refs.dropdown.visible = true;
    },
    onLeave: function onLeave() {
      this.$refs.dropdown.visible = false;
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["currentUser"])), {}, {
    getLanguageFlag: function getLanguageFlag() {
      return this.onLanguageChanged();
    }
  })
};