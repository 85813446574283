import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import KTHeader from "@/view/layout/admin/Header";
import KTHeaderMobile from "@/view/layout/admin/HeaderMobile.vue";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import ChatButton from "@/components/ChatButton.vue";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";
import { isMobile } from "@/lib/detectDevice";
export default {
  name: "LayoutAdmin",
  components: {
    KTHeader: KTHeader,
    KTHeaderMobile: KTHeaderMobile,
    KTSubheader: KTSubheader,
    KTFooter: KTFooter,
    KTScrollTop: KTScrollTop,
    Loader: Loader,
    ChatButton: ChatButton
  },
  beforeMount: function beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading"); // initialize html element classes

    HtmlClass.init(this.layoutConfig());
  },
  mounted: function mounted() {
    var _this = this;

    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({
        name: "login"
      });
    } // Simulate the delay page loading


    setTimeout(function () {
      // Remove page loader after some time
      _this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 200);
  },
  data: function data() {
    return {
      isMobile: isMobile()
    };
  },
  methods: {
    footerLayout: function footerLayout(type) {
      return this.layoutConfig("footer.layout") === type;
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["isAuthenticated", "breadcrumbs", "pageTitle", "layoutConfig"])), {}, {
    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled: function loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid: function contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo: function loaderLogo() {
      return this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled: function asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay: function toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay: function subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },

    /**
     * Set the subheader display on dashboard page
     * @returns {boolean}
     */
    displaySubheaderOnDashboard: function displaySubheaderOnDashboard() {
      return this.$route.name !== "dashboard";
    }
  })
};